import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetTopCompetitionsQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	first: Types.Scalars['Int']['input'];
	after?: Types.InputMaybe<Types.Scalars['String']['input']>;
	filter?: Types.InputMaybe<Types.SearchSportCompetitionFilter>;
}>;

export type GetTopCompetitionsQuery = {
	__typename?: 'Query';
	sportCompetitionsV2: {
		__typename?: 'SportCompetitionsConnectionV2';
		totalCount: number;
		edges?: Array<{
			__typename?: 'SportCompetitionsEdgeV2';
			cursor: string;
			node: {
				__typename?: 'SportCompetitionV2';
				content: { __typename?: 'SportCompetitionContentV2'; name: string; fullPath: string };
			};
		}> | null;
	};
};

export const GetTopCompetitionsDocument = gql`
	query GetTopCompetitions(
		$country: Country!
		$language: Language!
		$first: Int!
		$after: String
		$filter: SearchSportCompetitionFilter
	) {
		sportCompetitionsV2(country: $country, first: $first, after: $after, filter: $filter) {
			totalCount
			edges {
				cursor
				node {
					content(country: $country, language: $language) {
						name
						fullPath
					}
				}
			}
		}
	}
`;
