
import { namespace } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';
import { WebLocale } from '@/enums/web-locale';
import { hasUCConsentBanner } from '@/helpers/tracking';
import { ModalHelper } from '@/helpers/modal-helper';

// LowPriorityComponents already loads the consent-banner chunk. just making sure it's in the same chunk name.
const ConsentModal = () => import(/* webpackChunkName: "consent-banner" */ '@/components/modals/ConsentModal.vue');

const Language = namespace('language');

@Component({ name: 'ConsentToggleButton' })
export default class ConsentToggleButton extends Vue {
	@Language.State webLocale: WebLocale;

	openSettings() {
		if (hasUCConsentBanner(this.webLocale)) {
			window.UC_UI.showFirstLayer();
		} else {
			ModalHelper.openModal(ConsentModal);
		}
	}
}
