import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetSportsFooterEventsV2QueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	first: Types.Scalars['Int']['input'];
	filter?: Types.InputMaybe<Types.SportEventFilter>;
	timezoneOffset: Types.Scalars['Int']['input'];
}>;

export type GetSportsFooterEventsV2Query = {
	__typename?: 'Query';
	sportEventsV2: {
		__typename?: 'SportEventsConnectionV2';
		edges?: Array<{
			__typename?: 'SportEventsEdgeV2';
			node:
				| {
						__typename: 'MultiStepSportEvent';
						id: string;
						parent?: {
							__typename?: 'MultiStepSportEvent';
							id: string;
							content: {
								__typename?: 'MultiStepSportEventContent';
								name: string;
								fullPath?: string | null;
							};
						} | null;
						content: { __typename?: 'MultiStepSportEventContent'; name: string; fullPath?: string | null };
						competition?: {
							__typename?: 'SportCompetitionV2';
							id: string;
							content: { __typename?: 'SportCompetitionContentV2'; name: string; fullPath: string };
						} | null;
				  }
				| {
						__typename: 'SingleStepSportEvent';
						id: string;
						content: { __typename?: 'SingleStepSportEventContent'; name: string; fullPath?: string | null };
						competition?: {
							__typename?: 'SportCompetitionV2';
							id: string;
							content: { __typename?: 'SportCompetitionContentV2'; name: string; fullPath: string };
						} | null;
				  };
		}> | null;
	};
};

export const GetSportsFooterEventsV2Document = gql`
	query GetSportsFooterEventsV2(
		$country: Country!
		$language: Language!
		$first: Int!
		$filter: SportEventFilter
		$timezoneOffset: Int!
	) {
		sportEventsV2(country: $country, first: $first, filter: $filter, timezoneOffset: $timezoneOffset) {
			edges {
				node {
					__typename
					id
					content(country: $country, language: $language) {
						name
						fullPath
					}
					competition {
						id
						content(country: $country, language: $language) {
							name
							fullPath
						}
					}
					... on MultiStepSportEvent {
						parent {
							id
							content(country: $country, language: $language) {
								name
								fullPath
							}
						}
					}
				}
			}
		}
	}
`;
